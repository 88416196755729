<template>
  <div class="ml-document mb-5">
    <div class="container" v-if="detail">
      <div class="row">
<!--        <div class="col-lg-2 d-none d-lg-block">-->
<!--          <MenuLeft @selectClass="findClass"/>-->
<!--        </div>-->
        <div class="col-lg-12">
          <div class="filter-document d-flex justify-content-between">
            <CategorySelect class="d-none d-lg-block" placeholder="Môn" @findCategory="findCategory" :library-show="true"/>
            <FormSearch :value="query.keyword" @enter="handerSearch" />
          </div>
          <div v-if="keyword" class="text-primary text-break mt-3">
            {{ query.total }} kết quả tìm kiếm cho "<b>{{ keyword }}</b>"
          </div>
          <div class="form-content mt-3">
            <ViewMode :title="detail.name" @mode="changeMode" />
            <div class="row list-item">
              <DocumentItem :class="viewList ? 'col-sm-6 col-md-4 col-xl-3' : 'col-12'" :view-list="viewList" v-for="(item) in listData" :key="item.id" :item="item" :playlist="playlist" @createdPlaylist="loadPlayList()" @showNotify="showNotify"/>
            </div>
            <NoData v-if="!listData.length" text="Nội dung đang cập nhật"/>
            <Pagination class="mt-3" :total="query.total" :value="query.page" @change="loadData" :limit="query.limit" />
          </div>
        </div>
      </div>
      <PlaylistNotify :flag-notify="flagNotify" :text-notify="textNotify" @hide="flagNotify = false" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import CategorySelect from '~/components/mlearn/select/category.vue'
import FormSearch from '~/components/mlearn/select/form-search.vue'
import Pagination from '~/components/mlearn/pagination/index.vue'
import DocumentItem from '~/components/mlearn/document/item.vue'
// import MenuLeft from '~/components/mlearn/layout/menu/left.vue'
import { EventBus } from '~/common/utils/eventBus'
import ViewMode from '~/components/mlearn/view-mode/index.vue'
import PlaylistNotify from '~/components/mlearn/playlist/notify.vue'
import Resource from '~/common/api/resource'
const topicDetailResource = new Resource('library/topicFile/getBySlug')
const fileResource = new Resource('library/file')
const playlistResource = new Resource('library/playlist')
export default {
  components: {
    // MenuLeft,
    CategorySelect,
    FormSearch,
    DocumentItem,
    ViewMode,
    PlaylistNotify,
    Pagination
  },
  layout: 'mlearn',
  asyncData ({ isDev, route, store, env, params, req, res, redirect, error }) {
    const slug = params.slug
    const query = {
      slug,
      category_id: '',
      class_id: '',
      type: 'doc',
      keyword: '',
      page: 1,
      limit: 12,
      total: 0
    }
    const storeData = store.state.storeRedirect
    if (storeData) {
      query.category_id = storeData.category_id || ''
      query.class_id = storeData.class_id || ''
    }
    const listData = []
    const playlist = []
    const metaSeo = null
    const structuredData = {}
    return {
      detail: null,
      listData,
      query,
      playlist,
      loading: false,
      flagNotify: false,
      textNotify: '',
      keyword: '',
      metaSeo,
      structuredData,
      viewList: true
    }
  },
  head () {
    if (!this.metaSeo) {
      return {}
    }
    return {
      title: (this.metaSeo?.title || ''),
      meta: [
        { hid: 'title', name: 'title', content: (this.metaSeo?.title || '') },
        { hid: 'description', name: 'description', content: (this.metaSeo?.description || '') },
        { hid: 'keywords', name: 'keywords', content: (this.metaSeo?.keyword || '') },
        { hid: 'og:type', property: 'og:type', content: 'website' },
        { hid: 'og:title', property: 'og:title', content: (this.metaSeo?.title || '') },
        { hid: 'og:url', property: 'og:url', content: (process.env.NUXT_ENV_WEB_URL + this.$route.fullPath) },
        { hid: 'og:description', property: 'og:description', content: (this.metaSeo?.description || '') },
        { hid: 'og:image', property: 'og:image', content: (this.metaSeo?.image || '') },
        { hid: 'twitter:card', property: 'twitter:card', content: 'summary_large_image' },
        { hid: 'twitter:title', property: 'twitter:title', content: (this.metaSeo?.title || '') },
        { hid: 'twitter:url', property: 'twitter:url', content: (process.env.NUXT_ENV_WEB_URL + this.$route.fullPath) },
        { hid: 'twitter:description', property: 'twitter:description', content: (this.metaSeo?.description || '') },
        { hid: 'twitter:image', property: 'twitter:image', content: (this.metaSeo?.image || '') }
      ],
      link: [
        { rel: 'canonical', href: process.env.NUXT_ENV_WEB_URL + this.$route.fullPath }
      ],
      script: [{ type: 'application/ld+json', json: this.structuredData }]
    }
  },
  computed: {
    ...mapState([
      'user',
      'isMobile'
    ])
  },
  created () {
    EventBus.$on('handleChangeClassMenu', this.handleChangeClassMenu)
    this.$store.dispatch('setStoreRedirect', null)
    this.loadDetail()
    this.loadPlayList()
  },
  destroyed () {
    EventBus.$off('handleChangeClassMenu', this.handleChangeClassMenu)
  },
  methods: {
    changeMode (mode) {
      this.viewList = mode
    },
    async loadDetail (page = 1) {
      this.query.page = page
      const data = await topicDetailResource.list(this.query)
      if (!data) {
        this.$router.push('/khong-ton-tai')
        return
      }
      this.detail = data.data
      this.listData = this.detail.files
      this.query.total = data.total
    },
    handerSearch (keyword) {
      this.query.total = 0
      this.keyword = keyword
      this.query.keyword = keyword
      this.loadData()
    },
    findClass (classId) {
      this.query.class_id = classId
      this.query.total = 0
      this.loadData()
    },
    findCategory (categoryId) {
      this.query.category_id = categoryId
      this.query.total = 0
      this.loadData()
    },
    async loadData (page = 1) {
      this.query.page = page
      if (!this.keyword) {
        this.loadDetail(page)
        return
      }
      if (this.loading) { return }
      this.loading = true
      const data = await fileResource.list(this.query)
      this.listData = data.data || []
      this.query.total = data.total || 0
      this.keyword = this.query.keyword
      this.loading = false
    },
    async loadPlayList () {
      if (!this.user) { return }
      const { data } = await playlistResource.list({ type: 'doc' })
      this.playlist = data
    },
    showNotify (text) {
      this.flagNotify = false
      this.textNotify = text
      this.flagNotify = true
    },
    handleChangeClassMenu (classId) {
      this.findClass(classId)
    }
  }
}
</script>

<style lang="scss" scoped>
.ml-document{
  .form-content{
    background: var(--white);
    box-shadow: 0px 2px 4px rgba(202, 202, 202, 0.34);
    border-radius: 12px;
    padding:20px;
    @media (max-width:1366px) {
      padding:18px;
    }
    @media (max-width:576px) {
      padding:16px;
    }
    .list-item{
      > [class*="col-"] {
        padding-top: 20px;
        @media (max-width:1366px) {
          padding-top: 18px;
        }
        @media (max-width:576px) {
          padding-top: 16px;
        }
        &.col-12{
          padding-top: 0;
          border-bottom: 1px solid #EBEBEB;
          &:last-child{
            border-bottom: 0;
          }
        }
      }
    }
  }
}
</style>
